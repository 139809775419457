<template>
    <b-card class="mb-4"
        header-bg-variant="custom"
        no-body
    >
        <template #header>
            <h4 v-html="$t(tKey + 'header')" class="text-white" />
            <h6 v-html="$t(tKey + 'dataProtection')" class="light-link" />
        </template>
        <b-tabs pills justified class="my-3"
            active-nav-item-class="bg-transparent active-kpi"
            @input="setCurrentTab"
        >
            <b-tab v-for="value in data" :key="value.key">
                <template #title>
                    <b-card no-body class="small shadow-sm text-left text-dark mb-3 mb-lg-0">
                        <b-card-header class="p-2">
                            <span v-html="$t(tKey + value.key + '.header')" class="font-weight-bold" />
                        </b-card-header>
                        <b-card-body class="p-2">
                            <div v-if="value.data[0] !== -1 && value.data[1] !== -1">
                                <p class="mb-1" v-html="$utils.formatToFixed(value.key === 'occupation' ? value.data[0] * 100 : value.data[0]) + ' ' + ($tc(tKey + value.key + '.unit', value.data[0]))" />
                                <p class="mb-1 font-weight-bold" :class="$utils.isPositive(value.data[0], value.data[1]) ? 'text-success' : 'text-danger'">
                                    <font-awesome-icon class="mr-1" :icon="$utils.isPositive(value.data[0], value.data[1]) ? 'angle-up' : 'angle-down'" />
                                    {{ $utils.formatToFixed($utils.computeDifference(value.data[0], value.data[1])) }} %
                                </p>
                            </div>
                            <div class="text-muted" v-else>
                                <p class="my-2" v-html="$t(tKey + 'unavailable')" />
                            </div>
                        </b-card-body>
                    </b-card>
                </template>
                <b-card-body>   
                    <apexchart v-if="chart" type="line" height="350" :options="chartOptions" :series="series" />
                    <div v-else v-html="$t(tKey + 'noChart')" />
                </b-card-body>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
import moment from 'moment'
export default {
    name: 'KPICard',
    props: {        
        data: { type: Array, required: true },
        chart: { type: Array, required: false },
    },
    data() {
        return {
            tKey: 'dashboard.kpi.',
            currentTab: 0,
        }
    },
    computed: {
        chartOptions() {
            return {
                chart: {
                    height: 400,
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    toolbar: {
                        show: false
                    }
                },
                dataLabels: {
                    enabled: true
                },
                stroke: {
                    curve: 'straight'
                },
                title: {
                    text: this.$t(this.tKey + this.data[this.currentTab].key + '.chartTitle'),
                    align: 'left'
                },
                grid: {
                    row: {
                        colors: ['#f3f3f3', 'transparent'],
                        opacity: 0.5
                    },
                },
                xaxis: {
                    categories: this.chart[0].map((c) => moment().locale(this.$i18n.locale).set('year', c.year).set('month', c.month - 1).set('day', 1).format('MMM YYYY'))
                }                
            }
        },
        series() {
            return [
                {
                    name: this.$t(this.tKey + 'current'),
                    data: this.chart[0].map((c) => c.sum)
                },
                {
                    name: this.$t(this.tKey + 'previous'),
                    data: this.chart[1].map((c) => c.sum)
                }
            ]
        },
    },
    methods: {
        setCurrentTab(index) {
            this.currentTab = index
            this.$emit('update-kpi', this.data[index].key)
        },
    },
}
</script>

<style lang="scss">
.active-kpi {
    .card-header {
        background: theme-color('custom');
        color: theme-color('light');
    }
}
</style>